import axios, { AxiosRequestConfig } from "axios";

// Set config defaults when creating the instance
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const getFetcher = (url: string) => {
  const token = localStorage.getItem("token");

  let config: AxiosRequestConfig = {};

  if (token) {
    config = {
      headers: { Authorization: `Bearer ${token}` },
    };
  }

  console.debug(`Calling API GET: ${url}`);

  return instance.get(url, config);
};

export const postFetcher = (url: string, data: any) => {
  let config: AxiosRequestConfig = {};
  const token = localStorage.getItem("token");

  if (token) {
    config = {
      headers: { Authorization: `Bearer ${token}` },
    };
  }

  console.debug(`Calling API POST: ${url} with data: ${JSON.stringify(data)}`);

  return instance.post(url, data, config);
};

export const postFormDataFetcher = (url: string, formData: any) => {
  let config: AxiosRequestConfig = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const token = localStorage.getItem("token");

  if (token) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    };
  }

  console.debug(
    `Calling API POST: ${url} with data: ${JSON.stringify(formData)}`
  );

  return instance.post(url, formData, config);
};

export const putFetcher = (url: string, data: any) => {
  let config: AxiosRequestConfig = {};
  const token = localStorage.getItem("token");

  if (token) {
    config = {
      headers: { Authorization: `Bearer ${token}` },
    };
  }

  console.debug(`Calling API PUT: ${url} with data: ${JSON.stringify(data)}`);

  return instance.put(url, data, config);
};

export const deleteFetcher = (url: string) => {
  let config: AxiosRequestConfig = {};
  const token = localStorage.getItem("token");

  if (token) {
    config = {
      headers: { Authorization: `Bearer ${token}` },
    };
  }

  console.debug(`Calling API DELETE: ${url}`);

  return instance.delete(url, config);
};

interface OrderApproveReject {
  orderId: string;
  transactionId: string;
  approve: boolean;
  approveComment: string;
  rejectionComment?: string;
}
export const orderApproveReject = async (data: OrderApproveReject) => {
  return await postFetcher("/orders/approve-reject", data);
};

interface OrderAdminCancel {
  orderId: string;
  comment: string;
}
export const orderAdminCancel = async (data: OrderAdminCancel) => {
  return await postFetcher("/orders/admin-cancel", data);
};

interface AdminChangePassword {
  oldPassword: string;
  newPassword: string;
}
export const adminChangePassword = async (data: AdminChangePassword) => {
  return await postFetcher("/admins/change-password", data);
};
