import {
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  Edit,
  ReferenceInput,
  Toolbar,
  SaveButton,
} from "react-admin";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";

const OrderShippingToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const OrderShippingEdit = (props: any) => {
  const location = useLocation();
  const { orderId } = parse(location.search);

  const redirect = orderId ? `/orders/${orderId}/show/shippings` : false;

  return (
    <Edit {...props} title="Edit Shipping for Order" redirect={redirect}>
      <SimpleForm
        defaultValues={{ orderId }}
        toolbar={<OrderShippingToolbar />}
      >
        <ReferenceInput
          disabled
          label="Order Id"
          source="orderId"
          reference="orders"
        >
          <SelectInput optionText="publicId" />
        </ReferenceInput>
        <TextInput label="AWB" source="awb" validate={[required()]} />
        <SelectInput
          source="direction"
          choices={[
            { id: "TO_BUYER", name: "TO_BUYER" },
            { id: "TO_SELLER", name: "TO_SELLER" },
          ]}
          defaultValue={"TO_BUYER"}
        />
      </SimpleForm>
    </Edit>
  );
};

export default OrderShippingEdit;
