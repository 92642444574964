import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";
import { variantPropertySelection } from "../../template/all";

const SubProductVariantCreate = (props: any) => {
  const location = useLocation();
  const { subProductId } = parse(location.search);

  const redirect = subProductId
    ? `/sub-products/${subProductId}/show/variants`
    : false;

  return (
    <div>
      <Create {...props} redirect={redirect}>
        <SimpleForm defaultValues={{ subProductId }}>
          <ReferenceInput
            disabled
            label="Sub Product"
            source="subProductId"
            reference="sub-products"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ArrayInput source="variants">
            <SimpleFormIterator>
              <SelectInput
                label="Property"
                source="property"
                choices={variantPropertySelection()}
              />
              <TextInput source="value" label="Value" />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Create>
    </div>
  );
};

export default SubProductVariantCreate;
