import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  EmailField,
  TopToolbar,
  ListButton,
} from "react-admin";

const UserShowActions = ({
  basePath,
  data,
  resource,
}: {
  basePath?: string;
  data?: any;
  resource?: any;
}) => (
  <TopToolbar>
    <ListButton resource={resource} label="Back To List" />
  </TopToolbar>
);

const UserShow = (props: any) => {
  return (
    <Show {...props} actions={<UserShowActions />}>
      <TabbedShowLayout>
        <Tab label="Main">
          <TextField source="id" />
          <EmailField source="email" />
          <TextField source="phoneNumber" />
          <TextField source="status" />
          <DateField source="createdAt" locales="id-ID" />
          <DateField source="updatedAt" locales="id-ID" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default UserShow;
