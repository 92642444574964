import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  ImageField,
} from "react-admin";
import MediaReferenceInput from "../../utilities/MediaReferenceInput";

const CategoryEdit = (props) => {
  return (
    <Edit {...props} redirect={"list"}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput disabled source="slug" />
        <TextInput source="name" validate={[required()]} />
        <TextInput multiline source="description" />
        <ImageField label="Current Icon" source="iconUrl" />
        <MediaReferenceInput
          label="Choose Other Icon"
          source="iconMediaId"
          reference="media"
        />
        <SelectInput
          source="status"
          choices={[
            { id: "ACTIVE", name: "ACTIVE" },
            { id: "INACTIVE", name: "INACTIVE" },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default CategoryEdit;
