import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  DeleteButton,
  SaveButton,
  Toolbar,
  ReferenceInput,
  SelectInput,
  NumberInput,
  ImageField,
  minValue,
  useRedirect,
} from "react-admin";
import MediaReferenceInput from "../../utilities/MediaReferenceInput";

const SubCategoryToolbar = (props: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save Sub Product" type="button" />
      <DeleteButton label="Delete" type="button" />
    </Toolbar>
  );
};

const SubCategoryEdit = (props: any) => {
  const redirect = useRedirect();
  const onSuccess = (response: any) => {
    const productId =
      response && response.productId ? response.productId : null;

    const redirectUrl = productId
      ? `/products/${productId}/show/sub-products`
      : "/sub-products";

    redirect(redirectUrl);
  };

  return (
    <Edit
      mutationOptions={{
        onSuccess,
      }}
      mutationMode="pessimistic"
      {...props}
    >
      <SimpleForm toolbar={<SubCategoryToolbar />}>
        <ReferenceInput label="Product" source="productId" reference="products">
          <SelectInput optionText="name" disabled />
        </ReferenceInput>
        <TextInput source="id" disabled />
        <TextInput source="slug" disabled />
        <TextInput source="sku" validate={[required()]} />
        <TextInput source="name" validate={[required()]} />
        <TextInput source="displayName" validate={[required()]} />
        <TextInput source="description" />
        <NumberInput
          source="productWeight"
          label="Product Weight (g)"
          validate={[minValue(0, "Minimum value is 0")]}
        />
        <NumberInput
          source="boxWeight"
          label="Box Weight (g)"
          validate={[minValue(0, "Minimum value is 0")]}
        />
        <NumberInput
          source="totalWeight"
          label="Total Weight (g)"
          validate={[required(), minValue(0, "Minimum value is 0")]}
        />
        <NumberInput
          source="stockQuantity"
          validate={[required(), minValue(0, "Minimum value is 0")]}
        />
        <ImageField label="Current Main Image" source="mainImageUrl" />
        <ImageField
          label="Current Thumbnail Image"
          source="thumbnailImageUrl"
        />
        <MediaReferenceInput
          label="Main Image from Media"
          source="mainImageMediaId"
        />
        <MediaReferenceInput
          label="Thumbnail Image from Media"
          source="thumbnailImageMediaId"
        />
        <BooleanInput source="main" />
        <BooleanInput source="active" />
      </SimpleForm>
    </Edit>
  );
};

export default SubCategoryEdit;
