import { RichTextInput } from "ra-input-rich-text";
import {
  Create,
  TextInput,
  BooleanInput,
  SimpleForm,
  required,
  NumberInput,
  SelectInput,
  FormDataConsumer,
} from "react-admin";
import MediaReferenceInput from "../../utilities/MediaReferenceInput";

const RentCatalogCreate = (props: any) => {
  return (
    <Create {...props} redirect="list">
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput source="displayName" validate={[required()]} />
        <RichTextInput source="description" />
        <SelectInput
          source="onClickAction"
          choices={[
            { id: "OPEN_FILE", name: "OPEN_FILE" },
            { id: "OPEN_URL", name: "OPEN_URL" },
          ]}
          defaultValue={"OPEN_FILE"}
          validate={[required()]}
        />
        <MediaReferenceInput
          label="Image from Media"
          fileType="IMAGE"
          source="imageMediaId"
          validate={[required()]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.onClickAction === "OPEN_URL") {
              return (
                <div
                  style={{
                    display: "inline-flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <TextInput
                    source="clickUrl"
                    helperText="When user clicked the image, where should they be redirected to?"
                    validate={[required()]}
                  />
                </div>
              );
            } else {
              // OPEN_FILE
              return (
                <div
                  style={{
                    display: "inline-flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <MediaReferenceInput
                    label="File from Media"
                    source="fileUrlMediaId"
                    validate={[required()]}
                    fileType="DOCUMENT"
                    helperText="File media to be downloaded/viewed by the user"
                  />
                </div>
              );
            }
          }}
        </FormDataConsumer>
        <NumberInput
          source="position"
          validate={[required()]}
          defaultValue="1"
        />
        <BooleanInput source="active" defaultValue={true} />
      </SimpleForm>
    </Create>
  );
};

export default RentCatalogCreate;
