import {
  Edit,
  SimpleForm,
  BooleanInput,
  required,
  Toolbar,
  SaveButton,
  DeleteButton,
  TextInput,
  useRedirect,
} from "react-admin";

const ProductDescriptionToolbar = (props: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save Product Description" type="button" />
      <DeleteButton label="Delete" type="button" />
    </Toolbar>
  );
};

const redirectTo = (basePath: string, id: string, data: any) => {
  return `products/${data.productId}/show/descriptions`;
};

const ProductDescriptionEdit = (props: any) => {
  const redirect = useRedirect();
  const onSuccess = (response: any) => {
    const productId =
      response && response.productId ? response.productId : null;

    const redirectUrl = productId
      ? `/products/${productId}/show/descriptions`
      : "/products";

    redirect(redirectUrl);
  };

  return (
    <div>
      <Edit
        {...props}
        mutationOptions={{
          onSuccess,
        }}
        mutationMode="pessimistic"
        redirect={redirectTo}
      >
        <SimpleForm toolbar={<ProductDescriptionToolbar />}>
          <TextInput label="Name" source="name" validate={[required()]} />
          <TextInput
            label="Description"
            source="description"
            validate={[required()]}
          />
          <BooleanInput label="Active" source="active" defaultValue={true} />
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default ProductDescriptionEdit;
