import { Link } from "react-router-dom";
import ClassIcon from "@mui/icons-material/Class";
import { withStyles } from "@mui/styles";
import { Button, useRecordContext } from "react-admin";

const styles = {
  button: {
    marginTop: "1em",
    marginBottom: "1em",
  },
};

const AddSubCategoryButton = ({ classes }: { classes?: any }) => {
  const record = useRecordContext();
  return (
    <Button
      className={classes.button}
      variant="contained"
      component={Link as any}
      to={`/subcategories/create?categoryId=${record.id}`}
      label="Add Sub Category"
    >
      <ClassIcon />
    </Button>
  );
};

export default withStyles(styles)(AddSubCategoryButton) as any;
