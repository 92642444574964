import UserList from "./UserList";
import UserShow from "./UserShow";
import UserIcon from "@mui/icons-material/Person";

export { UserList, UserShow };

const User = {
  show: UserShow,
  list: UserList,
  icon: UserIcon,
  options: { label: "Users" },
};

export default User;
