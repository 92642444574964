import { useRecordContext } from "react-admin";

const PaymentConfirmationImage = () => {
  const record = useRecordContext();
  let transactions = null;
  let debitTrx = null;

  if (record && record.transactions) {
    transactions = record.transactions;
    debitTrx = transactions.find((trx: any) => trx.type === "DEBIT");
  } else {
    //Means single transaction
    if (record && record.type === "DEBIT") {
      debitTrx = record;
    }
  }

  if (
    debitTrx &&
    debitTrx.transactionConfirmation &&
    debitTrx.transactionConfirmation.proofOfPaymentImageUrl
  ) {
    return (
      <img
        src={debitTrx.transactionConfirmation.proofOfPaymentImageUrl}
        style={{
          margin: "0.5rem",
          maxHeight: "10rem",
        }}
        alt={record.invoice}
      />
    );
  }

  return <span>-</span>;
};

PaymentConfirmationImage.defaultProps = { label: "Payment Confirmation Image" };

const PaymentConfirmationTrfTo = () => {
  const record = useRecordContext();
  let transactions = null;
  let debitTrx = null;

  if (record && record.transactions) {
    transactions = record.transactions;
    debitTrx = transactions.find((trx: any) => trx.type === "DEBIT");
  } else {
    //Means single transaction
    if (record && record.type === "DEBIT") {
      debitTrx = record;
    }
  }

  if (debitTrx && debitTrx.transactionConfirmation) {
    const trxConfirmation = debitTrx.transactionConfirmation;

    return (
      <span>
        {trxConfirmation.transferToAccount} - {trxConfirmation.transferTo}
      </span>
    );
  }

  return <span>-</span>;
};

PaymentConfirmationTrfTo.defaultProps = { label: "Payment Made To" };

const PaymentConfirmationAmount = () => {
  const record = useRecordContext();
  let transactions = null;
  let debitTrx = null;

  if (record && record.transactions) {
    transactions = record.transactions;
    debitTrx = transactions.find((trx: any) => trx.type === "DEBIT");
  } else {
    //Means single transaction
    if (record && record.type === "DEBIT") {
      debitTrx = record;
    }
  }

  if (debitTrx && debitTrx.transactionConfirmation) {
    const trxConfirmation = debitTrx.transactionConfirmation;

    return (
      <span>
        {(trxConfirmation.transferAmount / 100).toLocaleString(undefined, {
          style: "currency",
          currency: record.currencyCode || "IDR",
        })}
      </span>
    );
  }

  return <span>-</span>;
};

PaymentConfirmationAmount.defaultProps = {
  label: "Payment Confirmation Amount",
};

export {
  PaymentConfirmationImage,
  PaymentConfirmationTrfTo,
  PaymentConfirmationAmount,
};
