import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  EditButton,
  ShowButton,
  ImageField,
  RichTextField,
} from "react-admin";

const RentCatalogList = (props: any) => {
  return (
    <List {...props} sort={{ field: "id", order: "DESC" }}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="displayName" />
        <RichTextField source="description" />
        <TextField source="onClickAction" />
        <ImageField source="imageUrl" label="Image" />
        <TextField source="clickUrl" label="Click URL" />
        <TextField source="fileUrl" label="File URL" />
        <TextField source="fileType" />
        <TextField source="position" />
        <BooleanField source="active" />

        <DateField source="createdAt" locales="id-ID" />
        <DateField source="updatedAt" locales="id-ID" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default RentCatalogList;
