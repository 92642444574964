import TagIcon from "@mui/icons-material/LabelImportant";
import TagCreate from "./TagCreate";
import TagEdit from "./TagEdit";
import TagList from "./TagList";
import TagShow from "./TagShow";

const Tag = {
  create: TagCreate,
  edit: TagEdit,
  list: TagList,
  icon: TagIcon,
  show: TagShow,
  options: { label: "Tags" },
};
export default Tag;
