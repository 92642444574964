import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  DeleteButton,
  SaveButton,
  Toolbar,
  ImageField,
  useRedirect,
  useRecordContext,
} from "react-admin";
import MediaReferenceInput from "../../utilities/MediaReferenceInput";

const SubCategoryToolbar = (props: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        label="Save Sub Category"
        type="button"
      />
      <DeleteButton
        label="Delete"
        type="button"
      />
    </Toolbar>
  );
};

const SubCategoryEdit = (props: any) => {
  const record = useRecordContext();
  const redirect = useRedirect();

  const onSuccess = (response: any) => {
    const categoryId =
      response && response.categoryId ? response.categoryId : null;
    const redirectUrl = categoryId
      ? `/categories/${categoryId}/show/subcategories`
      : "/categories";
    redirect(redirectUrl);
  };

  let redirectTo = `/categories/${record.categoryId}/show/subcategories`;

  return (
    <Edit
      {...props}
      mutationOptions={{
        onSuccess,
      }}
      mutationMode="pessimistic"
      redirect={redirectTo}
    >
      <SimpleForm toolbar={<SubCategoryToolbar />}>
        <TextInput disabled source="id" />
        <TextInput disabled source="slug" />
        <ReferenceInput source="categoryId" reference="categories">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" validate={[required()]} />
        <TextInput multiline source="description" />
        <ImageField label="Current Icon" source="iconUrl" />
        <MediaReferenceInput
          label="Choose Other Icon"
          source="iconMediaId"
          reference="media"
        />
        <SelectInput
          source="status"
          choices={[
            { id: "ACTIVE", name: "ACTIVE" },
            { id: "INACTIVE", name: "INACTIVE" },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default SubCategoryEdit;
