import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { withStyles } from "@mui/styles";
import { Button, useRecordContext } from "react-admin";

const styles = {
  button: {
    marginTop: "1em",
    marginBottom: "1em",
  },
};

const AddProductTagButton = ({ classes }: { classes: any }) => {
  const record = useRecordContext();

  return (
    <Button
      className={classes.button}
      variant="contained"
      component={Link as any}
      to={`/product-tags/create?productId=${record.id}`}
      label="Add Product Tags"
    >
      <AddIcon />
    </Button>
  );
};

export default withStyles(styles)(AddProductTagButton) as any;
