import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  Toolbar,
  SaveButton,
  DeleteButton,
  useRedirect,
} from "react-admin";

const AdminRoleToolbar = (props: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save Admin Role" type="button" />
      <DeleteButton label="Delete" />
    </Toolbar>
  );
};

const redirectTo = (basePath: string, id: any, data: any) => {
  return `admins/${data.adminId}/show/roles`;
};

const AdminRoleEdit = (props: any) => {
  const redirect = useRedirect();

  const onSuccess = (response: any) => {
    const adminId = response && response.adminId ? response.adminId : null;
    const redirectUrl = adminId ? `/admins/${adminId}/show/roles` : "/admins";
    redirect(redirectUrl);
  };

  return (
    <div>
      <Edit
        {...props}
        mutationOptions={{
          onSuccess,
        }}
        mutationMode="pessimistic"
        redirect={redirectTo}
      >
        <SimpleForm toolbar={<AdminRoleToolbar />}>
          <ReferenceInput
            disabled
            label="Admin"
            source="adminId"
            reference="admins"
          >
            <SelectInput optionText="id" />
          </ReferenceInput>
          <ReferenceInput
            label="Role"
            source="roleId"
            reference="roles"
            validate={[required()]}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default AdminRoleEdit;
