import { Edit, SimpleForm, TextInput, required } from "react-admin";

const RoleEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" label="Role Name" validate={[required()]} />
    </SimpleForm>
  </Edit>
);

export default RoleEdit;
