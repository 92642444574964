import {
  Create,
  SimpleForm,
  ReferenceInput,
  BooleanInput,
  TextInput,
  SelectInput,
  required,
} from "react-admin";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";

const ProductDescriptionCreate = (props: any) => {
  const location = useLocation();
  const { productId } = parse(location.search);

  const redirect = productId
    ? `/products/${productId}/show/descriptions`
    : false;

  return (
    <div>
      <Create {...props} redirect={redirect}>
        <SimpleForm defaultValues={{ productId }}>
          <ReferenceInput
            disabled
            label="Product"
            source="productId"
            reference="products"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput label="Name" source="name" validate={[required()]} />
          <TextInput
            label="Description"
            source="description"
            validate={[required()]}
          />
          <BooleanInput label="Active" source="active" defaultValue={true} />
        </SimpleForm>
      </Create>
    </div>
  );
};

export default ProductDescriptionCreate;
