import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  ShowButton,
  BooleanField,
  ImageField,
  EditButton,
  Filter,
  TextInput,
  NumberField,
} from "react-admin";
const SubProductsFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="SKU" source="sku" alwaysOn />
  </Filter>
);
const SubProductList = (props: any) => {
  return (
    <List
      {...props}
      sort={{ field: "id", order: "DESC" }}
      filters={<SubProductsFilter />}
    >
      <Datagrid>
        <TextField source="id" />
        <ReferenceField
          label="Product"
          source="productId"
          reference="products"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="slug" />
        <TextField source="sku" />
        <TextField source="name" />
        <TextField source="displayName" />
        <NumberField label="Stock Quantity" source="stockQuantity" />
        <ImageField label="Main Image" source="mainImageUrl" />
        <BooleanField source="main" />
        <BooleanField source="active" />
        <DateField source="createdAt" locales="id-ID" />
        <DateField source="updatedAt" locales="id-ID" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default SubProductList;
