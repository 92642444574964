import { Layout, LayoutProps, Sidebar } from "react-admin";
import NewAppBar from "./NewAppBar";

const CustomSidebar = (props: any) => <Sidebar {...props} size={200} />;

const MyLayout = (props: LayoutProps) => (
  <Layout {...props} appBar={NewAppBar} sidebar={CustomSidebar} />
);

export default MyLayout;
