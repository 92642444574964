import * as React from "react";
import { FC, Fragment, useCallback } from "react";
import Button from "@mui/material/Button";
import {
  Datagrid,
  DatagridProps,
  DateField,
  Filter,
  FilterProps,
  List,
  ListProps,
  ShowButton,
  TextField,
  TextInput,
  useGetList,
  useListContext,
  downloadCSV,
  useRecordContext,
} from "react-admin";
import { Divider, Tabs, Tab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import jsonExport from "jsonexport/dist";
import moment from "moment";
import DecimalNumberField from "../../utilities/DecimalNumberField";
import {
  PaymentConfirmationImage,
  PaymentConfirmationTrfTo,
} from "./PaymentConfirmationUtils";
import ShippingLabelDownload from "./ShippingLabelDownload";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface TabbedDatagridProps extends DatagridProps {}

const exporter = (orders: any) => {
  let ordersForExport = [];

  orders.forEach((order: any) => {
    const {
      publicId,
      currencyCode,
      status,
      initDate,
      grandTotal,
      discount,
      orderItems,
      recipientName,
      fullAddress,
      city,
      province,
    } = order;

    if (orderItems != null) {
      orderItems.forEach((e: any) => {
        ordersForExport.push({
          publicId,
          currencyCode,
          status,
          initDate,
          grandTotal,
          discount,
          itemSku: e.sku,
          itemQuantity: e.quantity,
          itemPrice: e.price,
          itemDiscount: e.discount,
          recipientName,
          fullAddress,
          city,
          province,
        });
      });
    }
  });

  jsonExport(
    ordersForExport,
    {
      headers: [
        "publicId",
        "currencyCode",
        "status",
        "initDate",
        "grandTotal",
        "discount",
        "itemSku",
        "itemQuantity",
        "itemPrice",
        "itemDiscount",
        "recipientName",
        "fullAddress",
        "city",
        "province",
      ], // order fields in the export
      rowDelimiter: ";",
      rename: [
        "Order ID",
        "Order Currency",
        "Order Status",
        "Init Date",
        "Order Grand Total",
        "Order Discount",
        "SKU Item Name",
        "Quantity",
        "Product Price",
        "Product Discount",
        "User Name",
        "Recipient Address",
        "City",
        "Province",
      ],
    },
    (err, csv) => {
      downloadCSV(csv, `titianjewelry-website-orders-${moment.now()}`); // download as 'orders.csv` file
    }
  );
};

const OrderFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <TextInput label="Order ID" source="publicOrderId" alwaysOn />
    <TextInput label="AWB" source="awb" alwaysOn />
    <TextInput label="Grand Total >" source="grandTotal_gte" />
  </Filter>
);

const useDatagridStyles = makeStyles({
  total: { fontWeight: "bold" },
});

const tabs = [
  { id: "PENDING_CONFIRMATION", name: "Pending Confirmation" },
  { id: "PROCESSING", name: "Processing" },
  { id: "SHIPPING", name: "Shipping" },
  { id: "ARRIVED", name: "Arrived" },
  { id: "ALL", name: "ALL" },
];

const useGetTotals = (filterValues: any) => {
  const { total: totalPendingConfirmation } = useGetList("orders", {
    pagination: { perPage: 1, page: 1 },
    sort: { field: "pendingConfirmationDate", order: "ASC" },
    filter: { ...filterValues, status: "PENDING_CONFIRMATION" },
  });

  const { total: totalProcessing } = useGetList("orders", {
    pagination: { perPage: 1, page: 1 },
    sort: { field: "processingDate", order: "ASC" },
    filter: { ...filterValues, status: "PROCESSING" },
  });

  const { total: totalShipping } = useGetList("orders", {
    pagination: { perPage: 1, page: 1 },
    sort: { field: "shippingDate", order: "ASC" },
    filter: { ...filterValues, status: "SHIPPING" },
  });

  const { total: totalArrived } = useGetList("orders", {
    pagination: { perPage: 1, page: 1 },
    sort: { field: "arrivedDate", order: "ASC" },
    filter: { ...filterValues, status: "ARRIVED" },
  });

  // HACKED FROM BACKEND, if ALL then remove status filter
  const { total: totalAll } = useGetList("orders", {
    pagination: { perPage: 1, page: 1 },
    sort: { field: "id", order: "DESC" },
    filter: { ...filterValues, status: "ALL" },
  });

  return {
    pendingConfirmation: totalPendingConfirmation,
    processing: totalProcessing,
    shipping: totalShipping,
    arrived: totalArrived,
    all: totalAll,
  };
};

const AWBField = () => {
  const record = useRecordContext();
  if (record && record.orderShippings && record.orderShippings.length > 0) {
    const toBuyerShipping = record.orderShippings.find(
      (e: any) => e.direction === "TO_BUYER"
    );

    if (toBuyerShipping) {
      return <span>{toBuyerShipping.awb}</span>;
    }
  }
  return <span>-</span>;
};

AWBField.defaultProps = { label: "AWB" };

const NewTabbedDatagrid: FC<TabbedDatagridProps> = (props) => {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;
  const totals = useGetTotals(filterValues) as any;
  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters(
          { ...filterValues, status: value },
          displayedFilters,
          false // no debounce, we want the filter to fire immediately
        );
    },
    [displayedFilters, filterValues, setFilters]
  );
  const classes = useDatagridStyles();

  // implemented to all data grids
  const newProps = {
    ...props,
  };
  newProps.bulkActionButtons = <OrderBulkActionButtons />;

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.status}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            label={
              totals[choice.name]
                ? `${choice.name} (${totals[choice.name]})`
                : choice.name
            }
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      {
        <>
          {filterValues.status === "PENDING_CONFIRMATION" && (
            <Datagrid {...newProps} optimized rowClick="edit">
              <TextField label="Order Id" source="publicId" />
              <DateField
                label="Pending Confirmation Date"
                source="pendingConfirmationDate"
                showTime
              />
              <TextField label="Total Items" source="orderItems.length" />
              <TextField label="Courier Code" source="courierCode" />
              <TextField label="Courier Service" source="courierService" />
              <TextField label="Payment Method" source="paymentMethod" />
              <PaymentConfirmationTrfTo />
              <PaymentConfirmationImage />
              <DecimalNumberField
                label="Grand Total"
                source="grandTotal"
                className={classes.total}
              />
              <ShowButton />
            </Datagrid>
          )}
          {filterValues.status === "PROCESSING" && (
            <Datagrid {...newProps} rowClick="edit">
              <TextField label="Order Id" source="publicId" />
              <DateField
                label="Processing Date"
                source="processingDate"
                showTime
              />
              <TextField source="recipientName" />
              <TextField source="country" />
              <TextField source="province" />
              <TextField source="city" />
              <TextField source="subdistrict" />
              <TextField source="postalCode" />
              <TextField label="Total Items" source="orderItems.length" />
              <TextField label="Courier Code" source="courierCode" />
              <TextField label="Courier Service" source="courierService" />
              <DecimalNumberField
                label="Grand Total"
                source="grandTotal"
                className={classes.total}
              />
              <ShippingLabelDownload display="Download" />
              <ShowButton />
            </Datagrid>
          )}
          {filterValues.status === "SHIPPING" && (
            <Datagrid {...newProps} rowClick="edit">
              <TextField label="Order Id" source="publicId" />
              <DateField label="Shipping Date" source="shippingDate" showTime />
              <TextField source="recipientName" />
              <TextField source="country" />
              <TextField source="province" />
              <TextField source="city" />
              <TextField source="subdistrict" />
              <TextField source="postalCode" />
              <TextField label="Total Items" source="orderItems.length" />
              <TextField label="Courier Code" source="courierCode" />
              <TextField label="Courier Service" source="courierService" />
              <AWBField />
              <DecimalNumberField
                label="Grand Total"
                source="grandTotal"
                className={classes.total}
              />
              <ShowButton />
            </Datagrid>
          )}
          {filterValues.status === "ARRIVED" && (
            <Datagrid {...newProps} rowClick="edit">
              <TextField label="Order Id" source="publicId" />
              <DateField label="Arrived Date" source="arrivedDate" showTime />
              <TextField source="recipientName" />
              <TextField source="country" />
              <TextField source="province" />
              <TextField source="city" />
              <TextField source="subdistrict" />
              <TextField source="postalCode" />
              <TextField label="Total Items" source="orderItems.length" />
              <TextField label="Courier Code" source="courierCode" />
              <TextField label="Courier Service" source="courierService" />
              <AWBField />
              <DecimalNumberField
                label="Grand Total"
                source="grandTotal"
                className={classes.total}
              />
              <ShowButton />
            </Datagrid>
          )}
          {filterValues.status === "ALL" && (
            <Datagrid {...newProps} rowClick="edit">
              <TextField label="Order Id" source="publicId" />
              <TextField label="Status" source="status" />
              <AWBField />
              <TextField label="Recipient" source="recipientName" />
              <TextField label="Courier Code" source="courierCode" />
              <TextField label="Courier Service" source="courierService" />
              <DateField label="Init Date" source="initDate" showTime />
              <DateField label="Shipping Date" source="shippingDate" showTime />
              <DateField label="Arrived Date" source="arrivedDate" showTime />
              <DateField label="Success Date" source="successDate" showTime />
              <DateField label="Failed Date" source="failedDate" showTime />
              <TextField label="Total Items" source="orderItems.length" />
              <DecimalNumberField
                label="Grand Total"
                source="grandTotal"
                className={classes.total}
              />
              <ShowButton />
            </Datagrid>
          )}
        </>
      }
    </Fragment>
  );
};

const OrderBulkActionButtons = (props: any) => {
  return (
    <Fragment>
      <Button
        style={{ marginBottom: "1rem" }}
        variant="contained"
        color="primary"
        onClick={() => {
          window.open(
            `${API_BASE_URL}/orders/shipping/label?ids=${props.selectedIds.join(
              ","
            )}`,
            "_blank"
          );
        }}
      >
        Download Label
      </Button>
    </Fragment>
  );
};

const OrderList: FC<ListProps> = (props) => (
  <List
    {...props}
    filterDefaultValues={{ status: "PENDING_CONFIRMATION" }}
    sort={{ field: "createdAt", order: "DESC" }}
    perPage={25}
    filters={<OrderFilter />}
    exporter={exporter}
  >
    <NewTabbedDatagrid />
  </List>
);

export default OrderList;
