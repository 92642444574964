const primaryColor = "#94807f";

const defaultButton = {
  background: primaryColor,
  color: "white",
  display: "inline-block",
  padding: "0.8rem 2rem",
  transition: "all 0.5s",
  border: "none",
  cursor: "pointer",
};

const DefaultStyles = {
  defaultButton,
};

export default DefaultStyles;
