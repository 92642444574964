import {
  Edit,
  SimpleForm,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  Toolbar,
  SaveButton,
  DeleteButton,
  useRedirect,
} from "react-admin";


const SubProductPriceToolbar = (props: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton type="button" label="Save Sub Product Price" />
      <DeleteButton label="Delete" />
    </Toolbar>
  );
};

const SubProductPriceEdit = (props: any) => {
  const redirect = useRedirect();

  const onSuccess = (response: any) => {

    const subProductId =
    response && response.subProductId ? response.subProductId : null;

    const redirectUrl = subProductId
      ? `/sub-products/${subProductId}/show/sub-product-prices`
      : "/sub-products";

    redirect(redirectUrl);
  };

  return (
    <div>
      <Edit
        {...props}
        mutationOptions={{
          onSuccess,
        }}
        mutationMode="pessimistic"
      >
        <SimpleForm toolbar={<SubProductPriceToolbar />}>
          <ReferenceInput
            label="Currency"
            source="currencyCode"
            reference="currencies"
          >
            <SelectInput optionText="code" validate={[required()]} />
          </ReferenceInput>
          <NumberInput
            label="Price"
            source="price"
            defaultValue={0}
            validate={[required()]}
          />
          <NumberInput label="Discount" source="discount" defaultValue={0} />
          <BooleanInput
            label="Active"
            source="active"
            validate={[required()]}
          />
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default SubProductPriceEdit;
