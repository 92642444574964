import {
  List,
  Datagrid,
  TextField,
  DateField,
  EmailField,
  ShowButton,
} from "react-admin";

const AdminList = (props: any) => (
  <List {...props} sort={{ field: "id", order: "DESC" }}>
    <Datagrid>
      <TextField source="id" />
      <EmailField source="email" />
      <TextField source="phoneNumber" />
      <TextField source="status" />
      <DateField source="createdAt" locales="id-ID" />
      <DateField source="updatedAt" locales="id-ID" />
      <ShowButton />
    </Datagrid>
  </List>
);

export default AdminList;
