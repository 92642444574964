import PromoCreate from "./PromoCreate";
import PromoList from "./PromoList";
import PromoIcon from "@mui/icons-material/Loyalty";
import PromoEdit from "./PromoEdit";

const Promos = {
  create: PromoCreate,
  edit: PromoEdit,
  list: PromoList,
  icon: PromoIcon,
  options: { label: "Promo" },
};

export default Promos;
