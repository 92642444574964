import { Create, SimpleForm, TextInput, required } from "react-admin";

const RoleCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" label="Role Name" validate={[required()]} />
    </SimpleForm>
  </Create>
);

export default RoleCreate;
