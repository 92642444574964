import RoleCreate from "./RoleCreate";
import RoleEdit from "./RoleEdit";
import RoleIcon from "@mui/icons-material/Accessibility";

const Roles = {
  create: RoleCreate,
  edit: RoleEdit,
  icon: RoleIcon,
  options: { label: "Roles" },
};

export default Roles;
