import CarouselList from "./CarouselList";
import CarouselCreate from "./CarouselCreate";
import CarouselEdit from "./CarouselEdit";
import CarouselShow from "./CarouselShow";
import CarouselIcon from "@mui/icons-material/ViewCarousel";

const Carousels = {
  create: CarouselCreate,
  list: CarouselList,
  edit: CarouselEdit,
  show: CarouselShow,
  icon: CarouselIcon,
  options: { label: "Carousels" },
};

export default Carousels;
