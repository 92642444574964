import {
  FormDataConsumer,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import MediaImagePreview from "./MediaImagePreview";
import { makeStyles } from "@mui/styles";
import MediaDocumentPreview from "./MediaDocumentPreview";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
});

const MediaReferenceInput = (props: any) => {
  const classes = useStyles();

  let isArrayInput = props && props.isArrayInput ? true : false;
  let fileType = props && props.fileType ? props.fileType : "IMAGE";
  let validate = props && props.validate ? props.validate : [];

  let newProps = { ...props };
  delete newProps.isArrayInput;

  return (
    <div className={classes.root}>
      <ReferenceInput
        {...newProps}
        filter={{ type: fileType }}
        reference="media"
        perPage={250}
      >
        <AutocompleteInput
          optionText="fullName"
          helperText={props.helperText}
          label={props.label}
          validate={validate}
        />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          // Show preview if type is IMAGE
          if (formData[newProps.source] && !isArrayInput) {
            if (fileType === "DOCUMENT") {
              return <MediaDocumentPreview id={formData[newProps.source]} />;
            } else {
              //IMAGE or OTHER
              return <MediaImagePreview id={formData[newProps.source]} />;
            }
          }
        }}
      </FormDataConsumer>
    </div>
  );
};

export default MediaReferenceInput;
