import DeleteIcon from "@mui/icons-material/Delete";
import { withStyles } from "@mui/styles";
import {
  Button,
  useDataProvider,
  useNotify,
  useRefresh,
  useRedirect,
  useRecordContext,
} from "react-admin";

const styles = {
  button: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    backgroundColor: "red",
  },
};

const DeleteAdminRoleButton = ({ classes }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const record = useRecordContext();

  const adminId = record && record.AdminRoles ? record.AdminRoles.adminId : 0;

  const handleClick = () => {
    dataProvider
      .delete("admin-roles", {
        adminId,
        roleId: record.AdminRoles.roleId,
      } as any)
      .then(({ data }) => {
        redirect(`/admins/${adminId}/show/roles`);
        refresh();
      })
      .catch((error) => {
        notify(`Error deleting admin role with message: ${error.message}`, {
          type: "error",
        });
        refresh();
      });
  };

  return (
    <Button
      className={classes.button}
      variant="contained"
      onClick={handleClick}
      label="Delete"
    >
      <DeleteIcon />
    </Button>
  );
};

export default withStyles(styles)(DeleteAdminRoleButton) as any;
