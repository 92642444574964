import { BooleanInput, Create, SimpleForm, TextInput } from "react-admin";

export const TagCreate = (props: any) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="description" />
      <BooleanInput source="showToUser" defaultValue={true} />
      <BooleanInput source="active" defaultValue={true} />
    </SimpleForm>
  </Create>
);

export default TagCreate;
