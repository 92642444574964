import {
  Edit,
  TextInput,
  BooleanInput,
  SimpleForm,
  SelectInput,
} from "react-admin";

const CurrencyEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled source="code" />
        <TextInput source="name" />
        <TextInput source="symbol" />
        <SelectInput
          source="symbolType"
          choices={[
            { id: "prefix", name: "prefix" },
            { id: "suffix", name: "suffix" },
          ]}
        />
        <BooleanInput source="active" />
      </SimpleForm>
    </Edit>
  );
};

export default CurrencyEdit;
