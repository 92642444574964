import {
  Create,
  TabbedForm,
  FormTab,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  FormDataConsumer,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import MediaReferenceInput from "../../utilities/MediaReferenceInput";
import MediaImagePreview from "../../utilities/MediaImagePreview";

const ProductCreate = (props) => {
  return (
    <Create {...props} redirect={"list"}>
      <TabbedForm>
        <FormTab label="Main">
          <ReferenceInput
            label="Category"
            source="categoryId"
            reference="categories"
            validate={[required()]}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.categoryId && (
                <ReferenceInput
                  label="Sub Category"
                  source="subCategoryId"
                  reference="subcategories"
                  validate={[required()]}
                  filter={{ categoryId: formData.categoryId }}
                  style={{ width: "256px" }}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>
          <TextInput source="name" validate={[required()]} />
          <TextInput source="displayName" validate={[required()]} />
          <TextInput source="pageTitle" validate={[required()]} />
          <RichTextInput
            label="English Description"
            source="enHtmlDescription"
            validate={[required()]}
          />
          <SelectInput
            source="status"
            choices={[
              { id: "ACTIVE", name: "ACTIVE" },
              { id: "INACTIVE", name: "INACTIVE" },
            ]}
            defaultValue="ACTIVE"
            validate={[required()]}
          />
          <ArrayInput label="Product Descriptions" source="productDescriptions">
            <SimpleFormIterator>
              <TextInput label="Name" source="name" validate={[required()]} />
              <TextInput
                label="Description"
                source="description"
                validate={[required()]}
              />
              <BooleanInput
                label="Active"
                source="active"
                defaultValue={true}
              />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput
            label="Product Galleries"
            source="productGalleries"
            validate={[required()]}
          >
            <SimpleFormIterator>
              <MediaReferenceInput
                label="Main Image from Media"
                source="mainImageMediaId"
                isArrayInput={true}
                validate={[required()]}
              />
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...rest }) => {
                  const exists =
                    scopedFormData && scopedFormData.mainImageMediaId;
                  if (exists) {
                    getSource("media");
                    return (
                      <MediaImagePreview
                        id={scopedFormData.mainImageMediaId}
                        imageWidth={"150px"}
                      />
                    );
                  }
                  return null;
                }}
              </FormDataConsumer>
              <MediaReferenceInput
                label="Thumbnail Image from Media"
                source="thumbnailMediaId"
                isArrayInput={true}
                validate={[required()]}
              />
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...rest }) => {
                  const exists =
                    scopedFormData && scopedFormData.thumbnailMediaId;
                  if (exists) {
                    getSource("media");
                    return (
                      <MediaImagePreview
                        id={scopedFormData.thumbnailMediaId}
                        imageWidth={"150px"}
                      />
                    );
                  }
                  return null;
                }}
              </FormDataConsumer>
              <TextInput
                label="Alt Text"
                source="altText"
                validate={[required()]}
              />
              <NumberInput
                label="Position"
                source="position"
                defaultValue="0"
                validate={[required()]}
              />
              <BooleanInput
                label="Active"
                source="active"
                defaultValue={true}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Tags" path="tags">
          <ArrayInput source="tags">
            <SimpleFormIterator>
              <TextInput source="" validate={[required()]} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default ProductCreate;
