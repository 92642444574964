import AdminList from "./AdminList";
import AdminCreate from "./AdminCreate";
import AdminShow from "./AdminShow";
import AdminIcon from "@mui/icons-material/SupervisorAccount";

export { AdminCreate, AdminList, AdminShow };

const Admin = {
  show: AdminShow,
  create: AdminCreate,
  list: AdminList,
  icon: AdminIcon,
  options: { label: "Admins" },
};

export default Admin;
