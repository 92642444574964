import { Link } from "react-router-dom";
import ImageIcon from "@mui/icons-material/Image";
import { withStyles } from "@mui/styles";
import { Button, useRecordContext } from "react-admin";

const styles = {
  button: {
    marginTop: "1em",
    marginBottom: "1em",
  },
};

const AddCarouselGalleryButton = ({ classes }: { classes?: any }) => {
  const record = useRecordContext();

  return (
    <Button
      className={classes.button}
      variant="contained"
      component={Link as any}
      to={`/carousel-galleries/create?carouselId=${record.id}`}
      label="Add Carousel Gallery"
    >
      <ImageIcon />
    </Button>
  );
};

export default withStyles(styles)(AddCarouselGalleryButton) as any;
