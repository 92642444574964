import CurrencyCreate from "./CurrencyCreate";
import CurrencyEdit from "./CurrencyEdit";
import CurrencyIcon from "@mui/icons-material/LocalAtm";

const Currencies = {
  create: CurrencyCreate,
  edit: CurrencyEdit,
  icon: CurrencyIcon,
  options: { label: "Currencies" },
};

export default Currencies;
