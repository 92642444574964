import CurrencyRateCreate from "./CurrencyRateCreate";
import CurrencyRateList from "./CurrencyRateList";
import CurrencyRateEdit from "./CurrencyRateEdit";
import CurrencyRateIcon from "@mui/icons-material/TrendingUp";

const CurrencyRate = {
  create: CurrencyRateCreate,
  list: CurrencyRateList,
  edit: CurrencyRateEdit,
  icon: CurrencyRateIcon,
  options: { label: "Currency Rates" },
};

export default CurrencyRate;
