import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { withStyles } from "@mui/styles";
import { Button, useRecordContext } from "react-admin";

const styles = {
  button: {
    marginTop: "1em",
    marginBottom: "1em",
  },
};

const AddOrderShippingsButton = ({ classes }: { classes: any }) => {
  const record = useRecordContext();

  return (
    <Button
      className={classes.button}
      variant="contained"
      component={Link as any}
      to={`/order-shippings/create?orderId=${record.id}`}
      label="Add Order Shippings"
    >
      <AddIcon />
    </Button>
  );
};

export default withStyles(styles)(AddOrderShippingsButton) as any;
