import SubCategoryCreate from "./SubCategoryCreate";
import SubCategoryEdit from "./SubCategoryEdit";
import SubCategoryIcon from "@mui/icons-material/Class";

const SubCategory = {
  create: SubCategoryCreate,
  edit: SubCategoryEdit,
  options: { label: "Sub Categories" },
  icon: SubCategoryIcon,
};
export default SubCategory;
