import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
} from "react-admin";
import { parse } from "query-string";
import MediaReferenceInput from "../../utilities/MediaReferenceInput";
import { useLocation } from "react-router-dom";

const CarouselGalleryCreate = (props: any) => {
  const { carouselId } = parse(useLocation().search);

  const redirect = carouselId
    ? `/carousels/${carouselId}/show/galleries`
    : false;

  return (
    <div>
      <Create {...props} redirect={redirect}>
        <SimpleForm defaultValues={{ carouselId }}>
          <ReferenceInput
            disabled
            label="Carousel"
            source="carouselId"
            reference="carousels"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="name" validate={[required()]} />
          <TextInput source="description" />
          <TextInput source="onClickUrl" />
          <TextInput source="onHoverText" validate={[required()]} />
          <TextInput source="altText" validate={[required()]} />
          <MediaReferenceInput
            label="Desktop Image from Media"
            source="desktopImageMediaId"
            reference="media"
            validate={[required()]}
          />
          <MediaReferenceInput
            label="Mobile Image from Media"
            source="mobileImageMediaId"
            reference="media"
            validate={[required()]}
          />
          <NumberInput source="position" validate={[required()]} />
          <BooleanInput source="active" />
        </SimpleForm>
      </Create>
    </div>
  );
};

export default CarouselGalleryCreate;
