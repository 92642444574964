import { Card, CardHeader } from "@mui/material";
import { getAdminData } from "../services/dataService";

const Dashboard = () => {
  const admin = getAdminData();
  const email = admin && admin.email ? admin.email : "Anonymous!";

  const title = `Welcome, ${email}!`;

  return (
    <Card>
      <CardHeader title={title} />
    </Card>
  );
};

export default Dashboard;
