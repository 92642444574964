import {
  Datagrid,
  ReferenceManyField,
  Show,
  EditButton,
  Tab,
  TabbedShowLayout,
  TextField,
  ImageField,
  DateField,
  TopToolbar,
  ListButton,
} from "react-admin";
import AddSubCategoryButton from "./AddSubCategoryButton";

const CategoryShowActions = ({
  basePath,
  data,
  resource,
}: {
  basePath?: string;
  data?: any;
  resource?: any;
}) => (
  <TopToolbar>
    <ListButton resource={resource} label="Back To List" />
  </TopToolbar>
);

const CategoryShow = (props: any) => {
  return (
    <Show {...props} actions={<CategoryShowActions />}>
      <TabbedShowLayout>
        <Tab label="Main">
          <TextField source="id" />
          <TextField source="slug" />
          <TextField source="name" />
          <TextField source="description" />
          <ImageField source="iconUrl" />
          <TextField source="status" />
          <DateField source="createdAt" locales="id-ID" />
          <DateField source="updatedAt" locales="id-ID" />
        </Tab>
        <Tab label="Sub Categories" path="subcategories">
          <ReferenceManyField
            reference="subcategories"
            target="categoryId"
            sort={{ field: "createdAt", order: "DESC" }}
          >
            <Datagrid>
              <TextField source="id" />
              <TextField source="slug" />
              <TextField source="name" />
              <TextField source="description" />
              <ImageField source="iconUrl" />
              <TextField source="status" />
              <DateField source="createdAt" locales="id-ID" />
              <DateField source="updatedAt" locales="id-ID" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <AddSubCategoryButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CategoryShow;
