import { Edit, SimpleForm, TextInput, BooleanInput } from "react-admin";

const redirect = (basePath: string, id: number, data: any) => {
  console.log(basePath, id, data);
  return `tags`;
};

export const TagEdit = (props: any) => (
  <Edit {...props} redirect={redirect}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="description" />
      <BooleanInput source="showToUser" defaultValue={true} />
      <BooleanInput source="active" defaultValue={true} />
    </SimpleForm>
  </Edit>
);

export default TagEdit;
