import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
} from "react-admin";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";

const AdminRoleCreate = (props: any) => {
  const location = useLocation();
  const { adminId } = parse(location.search);

  const redirect = adminId ? `/admins/${adminId}/show/roles` : false;

  return (
    <div>
      <Create {...props} redirect={redirect}>
        <SimpleForm defaultValues={{ adminId }}>
          <ReferenceInput
            disabled
            label="Admin"
            source="adminId"
            reference="admins"
          >
            <SelectInput optionText="id" />
          </ReferenceInput>
          <ReferenceInput
            label="Role"
            source="roleId"
            reference="roles"
            validate={[required()]}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleForm>
      </Create>
    </div>
  );
};

export default AdminRoleCreate;
