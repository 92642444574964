import {
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  NumberInput,
  BooleanInput,
  required,
  ImageField,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  minValue,
  maxValue,
} from "react-admin";
import MediaReferenceInput from "../../utilities/MediaReferenceInput";

export const PromoEdit = (props: any) => (
  <Edit {...props} redirect="show">
    <SimpleForm>
      <ReferenceInput
        label="Currency"
        source="currencyCode"
        reference="currencies"
        validate={[required()]}
      >
        <SelectInput optionText="code" />
      </ReferenceInput>
      <TextInput source="code" validate={[required()]} />
      <TextInput source="name" validate={[required()]} />
      <TextInput source="description" />
      <NumberInput
        label="Max Voucher Uses"
        source="maxUses"
        defaultValue={100}
        validate={[required()]}
      />
      <NumberInput
        label="Max Uses per User"
        source="maxUsesUser"
        defaultValue={1}
        validate={[required()]}
      />
      <BooleanInput
        label="Is Fixed"
        source="isFixed"
        defaultValue={false}
        validate={[required()]}
        helperText="true means in amount, false means in percentage"
      />
      <BooleanInput
        label="Is Private"
        source="isPrivate"
        defaultValue={false}
        validate={[required()]}
        helperText="Set true if voucher is not available for public"
      />
      <BooleanInput
        label="Promo Is Only for Shipping "
        source="isOnlyShipping"
        defaultValue={false}
        validate={[required()]}
        helperText="true means promo only applies to shipping, false means applies to subtotal"
      />
      <SelectInput
        source="type"
        choices={[{ id: "VOUCHER", name: "VOUCHER" }]}
        defaultValue="VOUCHER"
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const currencyCode = formData.currencyCode || "IDR";
          const isFixed = formData.isFixed || false;
          const discountAmountValidate = [required(), minValue(0, "Minimum 0")];

          if (!isFixed) {
            discountAmountValidate.push(maxValue(100, "Maximum 100%"));
          }

          return (
            <>
              <NumberInput
                label={`Discount Amount (${isFixed ? currencyCode : `%`})`}
                source="discountAmount"
                helperText={`${
                  isFixed
                    ? `Input in ${currencyCode} decimal`
                    : `Input in % (percentage)`
                }`}
                style={{ display: "block" }}
                validate={discountAmountValidate}
              />
              <NumberInput
                label={`Max Discount Amount (${currencyCode})`}
                source="maxDiscountAmount"
                helperText={`Input in ${currencyCode} decimal`}
                style={{ display: "block" }}
                validate={[required(), minValue(0, "Minimum 0")]}
              />
              <NumberInput
                label={`Minimum Purchase (${currencyCode})`}
                source="minPurchase"
                helperText={`Input in ${currencyCode} decimal`}
                style={{ display: "block" }}
                validate={[required(), minValue(0, "Minimum 0")]}
              />
            </>
          );
        }}
      </FormDataConsumer>
      <DateTimeInput source="startsAt" validate={[required()]} />
      <DateTimeInput source="expiresAt" validate={[required()]} />
      <DateTimeInput
        label="Start to show to user at"
        source="startShowAt"
        validate={[required()]}
      />
      <ImageField label="Current Banner" source="bannerImageUrl" />
      <MediaReferenceInput
        label="Banner Image from Media"
        source="bannerImageUrlMediaId"
      />
    </SimpleForm>
  </Edit>
);

export default PromoEdit;
