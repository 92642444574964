import {
  Create,
  TabbedForm,
  BooleanInput,
  TextInput,
  FormTab,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  minValue,
  AutocompleteInput,
} from "react-admin";
import { parse } from "query-string";
import { variantPropertySelection } from "../../template/all";
import MediaReferenceInput from "../../utilities/MediaReferenceInput";
import { useLocation } from "react-router-dom";

const SubProductCreate = (props: any) => {
  const location = useLocation();
  const { productId } = parse(location.search);

  const redirect = productId
    ? `/products/${productId}/show/sub-products`
    : `/sub-products`;

  return (
    <div>
      <Create {...props} redirect={redirect}>
        <TabbedForm defaultValues={{ productId }}>
          <FormTab label="Main">
            <ReferenceInput
              label="Product"
              source="productId"
              reference="products"
              perPage={100}
            >
              <AutocompleteInput
                optionText="name"
                disabled={productId ? true : false}
              />
            </ReferenceInput>
            <TextInput source="sku" validate={[required()]} />
            <TextInput source="name" validate={[required()]} />
            <TextInput source="displayName" validate={[required()]} />
            <TextInput source="description" />
            <NumberInput
              source="productWeight"
              label="Product Weight (g)"
              defaultValue="0"
              validate={[minValue(0, "Minimum value is 0")]}
            />
            <NumberInput
              source="boxWeight"
              label="Box Weight (g)"
              defaultValue="0"
              validate={[minValue(0, "Minimum value is 0")]}
            />
            <NumberInput
              source="totalWeight"
              label="Total Weight (g)"
              defaultValue="0"
              validate={[required(), minValue(0, "Minimum value is 0")]}
            />
            <NumberInput
              source="stockQuantity"
              defaultValue={0}
              validate={[required(), minValue(0, "Minimum value is 0")]}
            />
            <MediaReferenceInput
              label="Main Image from Media"
              source="mainImageMediaId"
            />
            <MediaReferenceInput
              label="Thumbnail Image from Media"
              source="thumbnailImageMediaId"
            />
            <BooleanInput source="main" defaultValue={false} />
            <BooleanInput source="active" defaultValue={true} />
          </FormTab>
          <FormTab label="Pricing" path="sub-product-prices">
            <ArrayInput source="subProductPrices" label="Sub Product Prices">
              <SimpleFormIterator>
                <ReferenceInput
                  label="Currency"
                  source="currencyCode"
                  reference="currencies"
                >
                  <SelectInput optionText="code" validate={[required()]} />
                </ReferenceInput>
                <NumberInput
                  label="Price"
                  source="price"
                  validate={[required()]}
                />
                <BooleanInput
                  label="Active"
                  source="active"
                  validate={[required()]}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
          <FormTab label="Variants" path="variants">
            <ArrayInput source="variants">
              <SimpleFormIterator>
                <SelectInput
                  label="Property"
                  source="property"
                  choices={variantPropertySelection()}
                />
                <TextInput source="value" label="Value" />
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
        </TabbedForm>
      </Create>
    </div>
  );
};

export default SubProductCreate;
