import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { parse } from "query-string";
import MediaReferenceInput from "../../utilities/MediaReferenceInput";
import { useLocation } from "react-router-dom";

const SubCategoryCreate = (props: any) => {
  const location = useLocation();
  const { categoryId } = parse(location.search);

  const redirect = categoryId
    ? `/categories/${categoryId}/show/subcategories`
    : false;

  return (
    <div>
      <Create {...props} redirect={redirect}>
        <SimpleForm defaultValues={{ categoryId }}>
          <ReferenceInput
            label="Category"
            source="categoryId"
            reference="categories"
            validate={[required()]}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="name" validate={[required()]} />
          <TextInput source="description" />
          <MediaReferenceInput
            label="Icon from Media"
            source="iconMediaId"
            reference="media"
          />
          <SelectInput
            source="status"
            choices={[
              { id: "ACTIVE", name: "ACTIVE" },
              { id: "INACTIVE", name: "INACTIVE" },
            ]}
            defaultValue="ACTIVE"
          />
        </SimpleForm>
      </Create>
    </div>
  );
};

export default SubCategoryCreate;
