import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { withStyles } from "@mui/styles";
import { Button, useRecordContext } from "react-admin";

const styles = {
  button: {
    marginTop: "1em",
    marginBottom: "1em",
  },
};

const AddAdminRoleButton = ({ classes }) => {
  const record = useRecordContext();
  return (
    <Button
      className={classes.button}
      variant="contained"
      component={Link}
      to={`/admin-roles/create?adminId=${record.id}`}
      label="Add Admin Roles"
    >
      <AddIcon />
    </Button>
  );
};

export default withStyles(styles)(AddAdminRoleButton) as any;
