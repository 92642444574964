import {
  Datagrid,
  ReferenceManyField,
  Show,
  EditButton,
  Tab,
  TabbedShowLayout,
  TextField,
  NumberField,
  BooleanField,
  ImageField,
  UrlField,
  ShowProps,
  List,
  TopToolbar,
  ExportButton,
} from "react-admin";
import AddCarouselGalleryButton from "./AddCarouselGalleryButton";

const CarouselGalleriesListActions = () => {
  return (
    <TopToolbar>
      <ExportButton />
    </TopToolbar>
  );
};

const CarouselShow = (props: ShowProps) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Main">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="mainCarousel" />
        </Tab>
        <Tab label="Carousel Galleries" path="galleries">
          <List actions={<CarouselGalleriesListActions />}>
            <ReferenceManyField
              reference="carousel-galleries"
              target="carouselId"
              sort={{ field: "position", order: "ASC" }}
            >
              <Datagrid>
                <TextField source="name" />
                <TextField source="description" />
                <UrlField source="onClickUrl" />
                <TextField source="onHoverText" />
                <TextField source="altText" />
                <ImageField source="desktopImageUrl" />
                <ImageField source="mobileImageUrl" />
                <NumberField source="position" />
                <BooleanField source="active" />
                <EditButton />
              </Datagrid>
            </ReferenceManyField>
          </List>
          <AddCarouselGalleryButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CarouselShow;
