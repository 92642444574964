import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  ReferenceInput,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";

const subCategoryOptionRenderer = (choice: any) => {
  const categoryName =
    choice && choice.category && choice.category.name
      ? choice.category.name
      : "";
  const subCategoryName = choice && choice.name ? choice.name : "";
  return `${categoryName} - ${subCategoryName}`.trim();
};

const ProductEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceInput
          label="Sub Category"
          source="subCategoryId"
          reference="subcategories"
          validate={[required()]}
        >
          <SelectInput optionText={subCategoryOptionRenderer} />
        </ReferenceInput>
        <TextInput disabled source="slug" />
        <TextInput source="name" validate={[required()]} />
        <TextInput source="displayName" validate={[required()]} />
        <TextInput source="pageTitle" validate={[required()]} />
        <RichTextInput
          label="English Description"
          source="enHtmlDescription"
          validate={[required()]}
        />
        <SelectInput
          source="status"
          choices={[
            { id: "ACTIVE", name: "ACTIVE" },
            { id: "INACTIVE", name: "INACTIVE" },
          ]}
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ProductEdit;
