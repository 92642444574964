import AdminRoleCreate from "./AdminRoleCreate";
import AdminRoleEdit from "./AdminRoleEdit";

const AdminRoles = {
  create: AdminRoleCreate,
  edit: AdminRoleEdit,
  options: { label: "Admin Roles" },
};

export default AdminRoles;
