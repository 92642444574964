import { useState } from "react";
import {
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  DateField,
  ArrayField,
  Datagrid,
  NumberField,
  ReferenceField,
  TopToolbar,
  ListButton,
  useRedirect,
  useRefresh,
  useNotify,
  useRecordContext,
  List,
  Button,
} from "react-admin";
import DecimalNumberField from "../../utilities/DecimalNumberField";
import {
  PaymentConfirmationAmount,
  PaymentConfirmationImage,
  PaymentConfirmationTrfTo,
} from "./PaymentConfirmationUtils";
import { orderApproveReject, orderAdminCancel } from "../../providers/backend";
import AddOrderShippingsButton from "./AddOrderShippingsButton";
import ShippingLabelDownload from "./ShippingLabelDownload";
import EditOrderShippingsButton from "./EditOrderShippingsButton";
import { useMutation } from "react-query";

const OrderShowActions = ({
  resource,
}: {
  basePath?: string;
  data?: any;
  resource?: string;
}) => {
  return (
    <TopToolbar>
      <ListButton resource={resource} label="Back To Orders" />
    </TopToolbar>
  );
};

const TransactionActions = () => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const record = useRecordContext();

  const { mutate: OrderApproveRejectMutate } = useMutation(
    async (data: any) => {
      try {
        try {
          await orderApproveReject({
            transactionId: record.id.toString(),
            orderId: record.orderId,
            approve: data.approve,
            approveComment: data.approveComment,
            rejectionComment: data.rejectionComment,
          });
          notify(`Success approve/reject for orderId: ${record.orderId}`);
          redirect("/orders");
        } catch (e) {
          console.error(e.response);
          const errorMessage =
            e.response.data && e.response.data.errorMessage
              ? e.response.data.errorMessage
              : "Error, please check log";

          notify(errorMessage, { type: "warning" });
          refresh();
        }
      } finally {
        setLoading(false);
      }
    }
  );

  if (!record) return null;

  const callApiApproveRejectTrx = (
    approve: boolean,
    approveComment: string,
    rejectionComment: string = null
  ) => {
    setLoading(true);
    OrderApproveRejectMutate({
      approve,
      approveComment,
      rejectionComment,
    });
  };

  //If pending, show button for approve/reject the payment
  const onApproveOrRejectTrx = (approve: boolean) => {
    if (approve) {
      const text =
        "Are you sure you want to approve this order? Please write down any comment";
      let approveComment = window.prompt(text);
      if (approveComment && approveComment.trim() !== "") {
        console.log(approveComment);
        callApiApproveRejectTrx(approve, approveComment);
      }
    } else {
      const text = "REJECT the order? What is the reason?";
      let rejectionComment = window.prompt(text);
      if (rejectionComment && rejectionComment.trim() !== "") {
        console.log(rejectionComment);
        callApiApproveRejectTrx(approve, null, rejectionComment);
      }
    }
  };

  if (record.status === "PENDING" && record.transactionConfirmation) {
    return (
      <div style={{ display: "flex", gap: "1rem" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onApproveOrRejectTrx(true)}
          disabled={loading}
          label="Approve"
        />

        <Button
          variant="contained"
          style={{ color: "white", backgroundColor: "red" }}
          onClick={() => onApproveOrRejectTrx(false)}
          disabled={loading}
          label="Reject"
        />
      </div>
    );
  }

  return <span>-</span>;
};

TransactionActions.defaultProps = { label: "Actions" };

//Cancel Order
const OrderCancel = () => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const record = useRecordContext();

  const { mutate: OrderAdminCancelMutate } = useMutation(
    async ({ comment }: { comment: string }) => {
      try {
        try {
          await orderAdminCancel({
            orderId: record.id.toString(),
            comment,
          });
          notify(`Success cancel order for orderId: ${record.id}`);
          redirect("/orders");
        } catch (e) {
          console.error(e.response);
          const errorMessage =
            e.response.data && e.response.data.errorMessage
              ? e.response.data.errorMessage
              : "Error, please check log";

          notify(errorMessage, { type: "warning" });
          refresh();
        }
      } finally {
        setLoading(false);
      }
    }
  );

  if (!record) return null;
  console.log(record);

  if (record.status !== "PROCESSING") {
    return <span></span>;
  }

  const callApiAdminCancelOrder = (comment: string) => {
    setLoading(true);
    OrderAdminCancelMutate({ comment });
  };

  const onCancelOrder = () => {
    const text =
      "CANCEL the order? What is the reason? Don't forget to refund the order manually to user";
    let cancelComment = window.prompt(text);
    if (cancelComment && cancelComment.trim() !== "") {
      callApiAdminCancelOrder(cancelComment);
    }
  };

  return (
    <div
      style={{
        marginBottom: "25px",
      }}
    >
      <Button
        variant="contained"
        style={{ color: "white", backgroundColor: "red" }}
        onClick={onCancelOrder}
        disabled={loading}
        label="Cancel Order"
      />
    </div>
  );
};
OrderCancel.defaultProps = { label: "Cancel Order" };

const OrderShow = (props: any) => {
  return (
    <Show {...props} actions={<OrderShowActions />}>
      <TabbedShowLayout>
        <Tab label="Main">
          <ShippingLabelDownload display="Shipping Label" />
          <ReferenceField
            label="User"
            source="userId"
            reference="users"
            link="show"
          >
            <TextField source="email" />
          </ReferenceField>
          <TextField label="Order Id" source="publicId" />
          <TextField label="Invoice" source="invoice" />
          <TextField source="status" />
          <TextField source="courierCode" />
          <TextField source="courierService" />

          <DecimalNumberField
            source="subTotal"
            label="Sub Total"
            parentStyle={{
              marginBottom: "0.8rem",
            }}
          />
          <DecimalNumberField
            source="shippingCost"
            label="Shipping Cost"
            parentStyle={{
              marginBottom: "0.8rem",
            }}
          />
          <DecimalNumberField
            source="total"
            label="Total"
            parentStyle={{
              marginBottom: "0.8rem",
            }}
          />
          <DecimalNumberField
            source="uniqueValue"
            label="Unique Value"
            parentStyle={{
              marginBottom: "0.8rem",
            }}
          />
          <DecimalNumberField
            source="itemDiscount"
            label="Discount from Item"
            parentStyle={{
              marginBottom: "0.8rem",
            }}
          />
          <DecimalNumberField
            source="discount"
            label="Discount from Promo"
            parentStyle={{
              marginBottom: "0.8rem",
            }}
          />
          <DecimalNumberField
            source="grandTotal"
            label="Grand Total"
            style={{
              fontWeight: "bold",
            }}
          />
          <TextField label="Promo Code" source="promoCode"></TextField>
          <DateField source="createdAt" locales="id-ID" showTime />
          <DateField source="updatedAt" locales="id-ID" showTime />
          <OrderCancel />
        </Tab>
        <Tab label="Items" path="items">
          <DecimalNumberField
            label="Sub Total"
            source="subTotal"
            parentStyle={{
              marginBottom: "0.8rem",
            }}
          />
          <ArrayField label="Order Items" source="orderItems">
              <Datagrid>
                <ReferenceField
                  label="Product"
                  source="subProduct.product.id"
                  reference="products"
                  link="show"
                >
                  <TextField source="displayName" />
                </ReferenceField>
                <ReferenceField
                  label="Sub Product"
                  source="subProduct.id"
                  reference="sub-products"
                  link="show"
                >
                  <TextField source="displayName" />
                </ReferenceField>
                <TextField source="sku" />
                <DecimalNumberField label="Price" source="price" />
                <DecimalNumberField label="Discount" source="discount" />
                <DecimalNumberField label="Final Price" source="finalPrice" />
                <NumberField source="quantity" />
                <DecimalNumberField label="Total Price" source="totalPrice" />
                <TextField source="comment" style={{ fontWeight: "bold" }} />
                <DateField source="createdAt" locales="id-ID" showTime />
                <DateField source="updatedAt" locales="id-ID" showTime />
              </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Recipient" path="recipient">
          <TextField label="Phone Number" source="phoneNumber" />
          <TextField label="Recipient Name" source="recipientName" />
          <TextField label="Full Address" source="fullAddress" />
          <TextField source="country" />
          <TextField label="Country Region" source="countryRegion" />
          <TextField source="province" />
          <TextField source="city" />
          <TextField source="subdistrict" />
          <TextField source="address" />
          <TextField source="postalCode" />
          <TextField label="Country ID" source="countryId" />
          <TextField label="Country Region ID" source="countryRegionId" />
          <TextField label="Province ID" source="provinceId" />
          <TextField label="City ID" source="cityId" />
          <TextField label="Subdistrict ID" source="subdistrictId" />
        </Tab>

        <Tab label="Transactions" path="transactions">
          <TextField label="Payment Method" source="paymentMethod" />
          <TextField label="Payment Channel" source="paymentChannel" />
          <DecimalNumberField
            source="grandTotal"
            label="Grand Total"
            style={{
              fontWeight: "bold",
            }}
          />
          <ArrayField label="Transactions" source="transactions">
            <Datagrid>
              <TextField label="Trx ID" source="id" />
              <TextField source="type" />
              <TextField source="status" style={{ fontWeight: "bold" }} />
              <PaymentConfirmationTrfTo />
              <PaymentConfirmationImage />
              <PaymentConfirmationAmount />
              <DateField source="pendingDate" locales="id-ID" showTime />
              <DateField source="successDate" locales="id-ID" showTime />
              <DateField source="failedDate" locales="id-ID" showTime />
              <TransactionActions />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Shippings" path="shippings">
          <ShippingLabelDownload display="Shipping Label" />
          <ArrayField label="Order Shippings" source="orderShippings">
            <Datagrid>
              <TextField label="Shipping ID" source="id" />
              <TextField label="Courier Code" source="courierCode" />
              <TextField label="Courier Service" source="courierService" />
              <TextField label="AWB" source="awb" />
              <TextField source="status" />
              <TextField source="direction" />
              <DateField source="pendingDate" locales="id-ID" showTime />
              <DateField source="finishDate" locales="id-ID" showTime />
              <EditOrderShippingsButton />
            </Datagrid>
          </ArrayField>
          <AddOrderShippingsButton />
        </Tab>
        <Tab label="Dates" path="dates">
          <DateField source="initDate" locales="id-ID" showTime />
          <DateField
            source="pendingConfirmationDate"
            locales="id-ID"
            showTime
          />
          <DateField source="processingDate" locales="id-ID" showTime />
          <DateField source="shippingDate" locales="id-ID" showTime />
          <DateField source="arrivedDate" locales="id-ID" showTime />
          <DateField source="successDate" locales="id-ID" showTime />
          <DateField source="failedDate" locales="id-ID" showTime />
          <TextField source="comment" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default OrderShow;
