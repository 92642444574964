import { Loading, useGetOne } from "react-admin";

const MediaDocumentPreview = ({ id }: { id: any }) => {
  const { data, isLoading, error } = useGetOne("media", { id: id });

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <a href={data.url} target="_blank" rel="noreferrer">
      Preview file
    </a>
  );
};

export default MediaDocumentPreview;
