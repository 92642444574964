import {
  Datagrid,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  BooleanField,
  TopToolbar,
  ListButton,
  EditButton,
  ArrayField,
  useRedirect,
  useRecordContext,
} from "react-admin";
import { Button } from "react-admin";
import ShowIcon from "@mui/icons-material/Visibility";

const TagShowActions = ({
  basePath,
  data,
  resource,
}: {
  basePath?: string;
  data?: any;
  resource?: string;
}) => {
  return (
    <TopToolbar>
      <ListButton resource={resource} label="Back To List" />
      <EditButton resource={resource} />
    </TopToolbar>
  );
};

const ShowProductButton = (props: any) => {
  const redirect = useRedirect();
  const record = useRecordContext();

  return (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      label="Show"
      onClick={() => {
        redirect(`/products/${record.id}/show`);
      }}
    >
      <ShowIcon />
    </Button>
  );
};

const TagProductsRender = (props: any) => {
  const record = useRecordContext();
  const haveProducts =
    record && record.products && record.products.length > 0 ? true : false;

  if (!haveProducts) {
    return <p>No products</p>;
  }
  return (
    <ArrayField {...props} source="products">
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="displayName" />
        <TextField source="pageTitle" />
        <ShowProductButton />
      </Datagrid>
    </ArrayField>
  );
};

const TagShow = (props: any) => {
  return (
    <Show {...props} actions={<TagShowActions />}>
      <TabbedShowLayout>
        <Tab label="Main">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="description" />
          <BooleanField source="showToUser" />
          <BooleanField source="active" />
        </Tab>
        <Tab label="Products" path="products">
          <TagProductsRender />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default TagShow;
