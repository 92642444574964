import DeleteIcon from "@mui/icons-material/Delete";
import { withStyles } from "@mui/styles";
import {
  Button,
  useDataProvider,
  useNotify,
  useRefresh,
  useRedirect,
  useRecordContext,
} from "react-admin";

const styles = {
  button: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    backgroundColor: "red",
  },
};

const DeleteAdminRoleButton = ({ classes }: { classes?: any }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const record = useRecordContext();

  const productId =
    record && record.ProductTags ? record.ProductTags.productId : 0;

  const handleClick = () => {
    dataProvider
      .delete("product-tags", {
        productId,
        tagId: record.ProductTags.tagId,
      } as any)
      .then(({ data }) => {
        redirect(`/products/${productId}/show/tags`);
        refresh();
      })
      .catch((error) => {
        notify(`Error deleting product tags with message: ${error.message}`);
        refresh();
      });
  };

  return (
    <Button
      className={classes.button}
      variant="contained"
      onClick={handleClick}
      label="Delete"
    >
      <DeleteIcon />
    </Button>
  );
};

export default withStyles(styles)(DeleteAdminRoleButton) as any;
