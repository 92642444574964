import OrderList from "./OrderList";
import OrderIcon from "@mui/icons-material/ShoppingCart";
import OrderShow from "./OrderShow";

const Order = {
  list: OrderList,
  icon: OrderIcon,
  show: OrderShow,
  options: { label: "Orders" },
};

export default Order;
