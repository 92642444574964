import {
  Create,
  TextInput,
  BooleanInput,
  SimpleForm,
  SelectInput,
  required,
} from "react-admin";

const CurrencyCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="code" validate={[required()]} />
        <TextInput source="name" validate={[required()]} />
        <TextInput source="symbol" validate={[required()]} />
        <SelectInput
          source="symbolType"
          choices={[
            { id: "prefix", name: "prefix" },
            { id: "suffix", name: "suffix" },
          ]}
          validate={[required()]}
        />
        <BooleanInput source="active" />
      </SimpleForm>
    </Create>
  );
};

export default CurrencyCreate;
