import CarouselGalleryCreate from "./CarouselGalleryCreate";
import CarouselGalleryEdit from "./CarouselGalleryEdit";

const CarouselGalleries = {
  create: CarouselGalleryCreate,
  edit: CarouselGalleryEdit,
  options: { label: "Carousel Gallery" },
};

export default CarouselGalleries;
