import { useRecordContext } from "react-admin";

const DecimalNumberField = (props: any) => {
  const record = useRecordContext();
  if (!record) {
    return <span>-</span>;
  }
  const number = record[props.source] || 0;
  const parentStyle = props.parentStyle ? props.parentStyle : {};

  // To prevent basePath error in console
  const newProps = {
    ...props,
  };
  delete newProps.basePath;
  delete newProps.parentStyle;

  let display = 0;

  if (number && number !== 0) {
    display = number / 100;
  }

  let currencyCode = record.currencyCode ? record.currencyCode : "IDR";

  // if (showProps) {
  //   return (
  //     <div >
  //       <span
  //         style={{
  //           color: "rgba(0, 0, 0, 0.54)",
  //           fontWeight: 400,
  //           fontSize: "0.8rem",
  //           letterSpacing: "0.00938em",
  //           marginBottom: "0.7rem",
  //           display: "block",
  //         }}
  //       >
  //         {showProps}
  //       </span>
  //       <span {...newProps}>
  //         {display.toLocaleString(undefined, {
  //           style: "currency",
  //           currency: currencyCode,
  //         })}
  //       </span>
  //     </div>
  //   );
  // }

  return (
    <span style={parentStyle} {...newProps}>
      {display.toLocaleString(undefined, {
        style: "currency",
        currency: currencyCode,
      })}
    </span>
  );
};

export default DecimalNumberField;
