import RentCatalogCreate from "./RentCatalogCreate";
import RentCatalogEdit from "./RentCatalogEdit";
import RentCatalogIcon from "@mui/icons-material/MenuBook";
import RentCatalogList from "./RentCatalogList";
import RentCatalogShow from "./RentCatalogShow";

const RentCatalogs = {
  create: RentCatalogCreate,
  edit: RentCatalogEdit,
  list: RentCatalogList,
  icon: RentCatalogIcon,
  show: RentCatalogShow,
  options: { label: "Rent Catalogs" },
};

export default RentCatalogs;
