import {
  Edit,
  SimpleForm,
  BooleanInput,
  required,
  Toolbar,
  SaveButton,
  DeleteButton,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  ImageField,
  useRedirect,
  useRecordContext,
} from "react-admin";
import MediaReferenceInput from "../../utilities/MediaReferenceInput";

const ProductGalleryToolbar = (props: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save Product Gallery" type="button" />
      <DeleteButton label="Delete" type="button" />
    </Toolbar>
  );
};

const ProductGalleryEdit = (props: any) => {
  const record = useRecordContext();
  const redirect = useRedirect();

  const onSuccess = (response: any) => {
    const productId =
      response && response.productId ? response.productId : null;

    const redirectUrl = productId
      ? `/products/${productId}/show/galleries`
      : "/products";

    redirect(redirectUrl);
  };

  let redirectTo = `/products/${record.productId}/show/galleries`;

  return (
    <div>
      <Edit
        {...props}
        mutationOptions={{
          onSuccess,
        }}
        mutationMode="pessimistic"
        redirect={redirectTo}
      >
        <SimpleForm toolbar={<ProductGalleryToolbar />}>
          <ReferenceInput
            disabled
            label="Product"
            source="productId"
            reference="products"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ImageField label="Current Main Image" source="mainImageUrl" />
          <ImageField label="Current Thumbnail Image" source="thumbnailUrl" />
          <MediaReferenceInput
            label="Main Image from Media"
            source="mainImageMediaId"
          />
          <MediaReferenceInput
            label="Thumbnail Image from Media"
            source="thumbnailMediaId"
          />
          <TextInput
            label="Alt Text"
            source="altText"
            validate={[required()]}
          />
          <NumberInput label="Position" source="position" defaultValue={0} />
          <BooleanInput label="Active" source="active" defaultValue={true} />
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default ProductGalleryEdit;
