import {
  Create,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  CreateProps,
  SelectInput,
  FileInput,
  FormDataConsumer,
  ImageInput,
  ImageField,
  FileField,
} from "react-admin";

const CarouselCreate = (props: CreateProps) => {
  return (
    <div>
      <Create {...props} redirect="list">
        <SimpleForm>
          <SelectInput
            source="type"
            choices={[
              { id: "IMAGE", name: "IMAGE" },
              { id: "VIDEO", name: "VIDEO" },
              { id: "DOCUMENT", name: "DOCUMENT" },
            ]}
            defaultValue={"IMAGE"}
          />
          <TextInput
            source="name"
            validate={[required()]}
            helperText="unique name for reference when selecting media"
          />
          <SelectInput
            label="Select Method"
            source="method"
            choices={[
              { id: "AUTO", name: "AUTO" },
              { id: "MANUAL_INPUT", name: "MANUAL_INPUT" },
            ]}
            defaultValue="AUTO"
            validate={required()}
          />

          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (formData && formData.method === "MANUAL_INPUT") {
                return (
                  <div
                    style={{
                      display: "inline-flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <TextInput
                      label="URL to Media"
                      source="url"
                      helperText="Full URL to Media"
                      validate={required()}
                    ></TextInput>
                    <TextInput
                      label="MIME Type"
                      source="mimeType"
                      helperText="ex. text/csv or image/jpeg"
                      validate={required()}
                    ></TextInput>
                  </div>
                );
              } else {
                return (
                  <div>
                    <TextInput
                      label="Upload to Folder"
                      source="path"
                      validate={[required()]}
                      helperText="ex. neklace/rosegold/variant1. Resulting URL would be: neklace/rosegold/variant1/filename.xxx"
                    />
                    {formData.type === "IMAGE" ? (
                      <ImageInput
                        label="Media File"
                        source="mediaFile"
                        maxSize={5000000}
                        accept="image/*"
                        validate={[required()]}
                      >
                        <ImageField source="src" title="title" />
                      </ImageInput>
                    ) : (
                      <FileInput
                        label="Media File"
                        source="mediaFile"
                        maxSize={10000000}
                        validate={[required()]}
                      >
                        <FileField source="src" title="title"></FileField>
                      </FileInput>
                    )}
                  </div>
                );
              }
            }}
          </FormDataConsumer>
          <BooleanInput source="active" defaultValue={true} />
        </SimpleForm>
      </Create>
    </div>
  );
};

export default CarouselCreate;
