import { HttpError } from "react-admin";
import { stringify } from "query-string";

const apiUrl =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8888/api-admin";
const useCodeResources = ["currencies"];

const httpClient = (url: string, options: any = {}) => {
  const token = localStorage.getItem("token");

  const requestHeaders =
    options.headers ||
    new Headers({
      Accept: "application/json",
    });

  if (
    !requestHeaders.has("Content-Type") &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set("Content-Type", "application/json");
  }

  if (token) {
    requestHeaders.set("Authorization", `Bearer ${token}`);
  }

  return fetch(url, { ...options, headers: requestHeaders })
    .then((response) =>
      response.text().then((text) => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      }))
    )
    .then(({ status, statusText, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        return Promise.reject(
          new HttpError((json && json.errorMessage) || statusText, status, json)
        );
      }
      return Promise.resolve({ status, headers, body, json });
    });
};

const DataProvider = {
  getList: async (resource: any, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    let useField = field;

    //Handle finding by id
    if (field === "id" && useCodeResources.includes(resource)) {
      useField = "code";
    }

    const query = {
      order: JSON.stringify([useField, order]),
      offset: (page - 1) * perPage,
      limit: perPage,
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      return Promise.resolve({
        data: json,
        total: parseInt(headers.get("x-total-count") as any),
      });
    });
  },

  getOne: async (resource: any, params: any) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`).then(
      ({ json }) => ({
        data: json.data,
      })
    );
  },

  getMany: async (resource: any, params: any) => {
    let key = "id";

    if (useCodeResources.includes(resource)) {
      key = "code";
    }

    const query = {
      filter: JSON.stringify({ [key]: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: async (resource: any, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      order: JSON.stringify([field, order]),
      offset: (page - 1) * perPage,
      limit: perPage,
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("x-total-count") as any),
    }));
  },

  update: async (resource: any, params: any) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...json.data, id: json.data.id },
    }));
  },
  updateMany: async (resource: any, params: any) => {
    //TODO: Call API one by one https://github.com/marmelab/react-admin/blob/master/packages/ra-data-simple-rest/src/index.ts
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };

    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: async (resource: any, params: any) => {
    if (resource !== "media") {
      // fallback to the default implementation
      return httpClient(`${apiUrl}/${resource}`, {
        method: "POST",
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({
        data: { ...json.data, id: json.data.id },
      }));
    }

    let formData = getFormData(params.data);

    if (params.data.mediaFile) {
      formData.set("mediaFile", params.data.mediaFile.rawFile);
    }

    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: formData,
    }).then(({ json }) => ({
      data: { ...json.data, id: json.data.id },
    }));
  },

  delete: async (resource: any, params: any) => {
    if (
      ["admin-roles", "sub-product-variants", "product-tags"].includes(resource)
    ) {
      return httpClient(`${apiUrl}/${resource}?${stringify(params)}`, {
        method: "DELETE",
      }).then(({ json }) => ({ data: json }));
    } else {
      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "DELETE",
      }).then(({ json }) => ({ data: json }));
    }
  },
  deleteMany: (resource: any, params: any) =>
    Promise.all(
      params.ids.map((id: number) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "DELETE",
        })
      )
    ).then((responses) => ({
      data: responses.map(({ json }) => json.data.id),
    })),
};

const getFormData = (object: any) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
};

export default DataProvider;
