import { RichTextInput } from "ra-input-rich-text";
import {
  Edit,
  TextInput,
  BooleanInput,
  SimpleForm,
  SelectInput,
  required,
  FormDataConsumer,
  NumberInput,
  ImageField,
  FileField,
} from "react-admin";
import MediaReferenceInput from "../../utilities/MediaReferenceInput";

const CurrencyEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput source="displayName" validate={[required()]} />
        <RichTextInput source="description" />
        <SelectInput
          source="onClickAction"
          choices={[
            { id: "OPEN_FILE", name: "OPEN_FILE" },
            { id: "OPEN_URL", name: "OPEN_URL" },
          ]}
          defaultValue={"OPEN_FILE"}
          validate={[required()]}
        />
        <ImageField title="Current Image" source="imageUrl" />
        <MediaReferenceInput
          label="New Image from Media"
          fileType="IMAGE"
          source="imageMediaId"
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.onClickAction === "OPEN_URL") {
              return (
                <div
                  style={{
                    display: "inline-flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <TextInput
                    source="clickUrl"
                    helperText="When user clicked the image, where should they be redirected to?"
                    validate={[required()]}
                  />
                </div>
              );
            } else {
              // OPEN_FILE
              return (
                <div
                  style={{
                    display: "inline-flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <FileField
                    source="fileUrl"
                    title="Current File"
                    target="_blank"
                  />
                  <MediaReferenceInput
                    label="New File from Media"
                    source="fileUrlMediaId"
                    fileType="DOCUMENT"
                    helperText="File media to be downloaded/viewed by the user"
                  />
                </div>
              );
            }
          }}
        </FormDataConsumer>
        <NumberInput
          source="position"
          validate={[required()]}
          defaultValue="1"
        />
        <BooleanInput source="active" defaultValue={true} />
      </SimpleForm>
    </Edit>
  );
};

export default CurrencyEdit;
