import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  NumberField,
  EditButton,
  ShowButton,
  useRecordContext,
} from "react-admin";
import DecimalNumberField from "../../utilities/DecimalNumberField";

const DiscountAmount = ({ source }: { source: string }) => {
  const record = useRecordContext();

  if (record.isFixed) {
    return <DecimalNumberField record={record} source={source} />;
  }

  return <span>{`${record.discountAmount}%`}</span>;
};
DiscountAmount.defaultProps = {
  label: "Discount Amount",
};

export const PromoList = (props: any) => (
  <List {...props} sort={{ field: "id", order: "DESC" }}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="currencyCode" />
      <TextField source="code" />
      <TextField source="name" />
      <NumberField source="uses" />
      <NumberField source="maxUses" />
      <NumberField label="Max / User" source="maxUsesUser" />
      <TextField source="type" />
      <DiscountAmount source="discountAmount" />
      <DecimalNumberField source="maxDiscountAmount" />
      <BooleanField source="isFixed" />
      <BooleanField source="isPrivate" />
      <BooleanField label="Is Shipping Only" source="isOnlyShipping" />
      <DateField source="startsAt" locales="id-ID" showTime />
      <DateField source="expiresAt" locales="id-ID" showTime />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default PromoList;
