import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ListProps,
  useNotify,
  DeleteButton,
  useRecordContext,
} from "react-admin";
import ImagePreview from "./ImagePreview";
import defaultStyles from "../../styles/defaultStyles";

const CopyUrlButton = () => {
  const notify = useNotify();
  const record = useRecordContext();

  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = record.url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    notify("URL copied to clipboard", { type: "success" });
  };

  return (
    <button style={defaultStyles.defaultButton} onClick={copyToClipboard}>
      Copy Url
    </button>
  );
};
CopyUrlButton.defaultProps = {
  label: "Copy Url",
};

const CarouselList = (props: ListProps) => {
  return (
    <List {...props} sort={{ field: "id", order: "DESC" }}>
      <Datagrid>
        <CopyUrlButton />
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="type" />
        <TextField label="MIME Type" source="mimeType" />
        <BooleanField source="active" />
        <ImagePreview />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default CarouselList;
