import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  ShowButton,
  Filter,
  TextInput,
} from "react-admin";

const TagsFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
  </Filter>
);
export const TagList = (props: any) => (
  <List
    {...props}
    sort={{ field: "id", order: "DESC" }}
    perPage={25}
    filters={<TagsFilter />}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <BooleanField source="showToUser" />
      <BooleanField source="active" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default TagList;
