const getAdminData = () => {
  const rawAdmin = localStorage.getItem('admin');

  if(!rawAdmin) {
    return null;
  }

  return JSON.parse(rawAdmin);
}
export {
  getAdminData
}