import {
  Create,
  SimpleForm,
  TextInput,
  email,
  required,
  PasswordInput,
} from "react-admin";

const validateAdminCreation = (values: any) => {
  const errors: any = {};
  if (!values.firstName) {
    errors.firstName = "The firstName is required";
  }
  if (!values.lastName) {
    errors.lastName = "The lastName is required";
  }
  if (!values.email) {
    errors.email = "The email is required";
  }
  if (!values.password) {
    errors.password = "The password is required";
  }
  if (!values.verifyPassword) {
    errors.verifyPassword = "The verifyPassword is required";
  }

  if (values.password && values.verifyPassword) {
    if (values.password !== values.verifyPassword) {
      errors.password = "Password mismatch";
      errors.verifyPassword = "Password mismatch";
    }
  }
  return errors;
};

const AdminCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm validate={validateAdminCreation}>
      <TextInput
        source="firstName"
        label="First Name"
        validate={[required()]}
      />
      <TextInput source="middleName" label="Middle Name" />
      <TextInput source="lastName" label="Last Name" validate={[required()]} />
      <TextInput
        source="email"
        type="email"
        label="Email"
        validate={[required(), email()]}
      />
      <PasswordInput source="password" validate={[required()]} />
      <PasswordInput
        source="verifyPassword"
        label="Verify Password"
        validate={[required()]}
      />
      <TextInput source="phoneNumber" label="Phone Number" />
    </SimpleForm>
  </Create>
);

export default AdminCreate;
