import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";

const ProductTagsCreate = (props: any) => {
  const location = useLocation();
  const { productId } = parse(location.search);

  const redirect = productId ? `/products/${productId}/show/tags` : false;

  return (
    <div>
      <Create title="Add Product Tags" {...props} redirect={redirect}>
        <SimpleForm defaultValues={{ productId }}>
          <ReferenceInput
            disabled
            label="Product"
            source="productId"
            reference="products"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceArrayInput
            label="Tags"
            source="tagIds"
            reference="tags"
            filter={{ active: true }}
            sort={{ field: "name", order: "ASC" }}
            perPage={100}
          >
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
        </SimpleForm>
      </Create>
    </div>
  );
};

export default ProductTagsCreate;
