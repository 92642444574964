import {
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  ImageField,
  DateField,
  TopToolbar,
  ListButton,
  BooleanField,
  RichTextField,
} from "react-admin";

const RentalCatalogShowActions = ({
  basePath,
  data,
  resource,
}: {
  basePath?: string;
  data?: any;
  resource?: any;
}) => (
  <TopToolbar>
    <ListButton resource={resource} label="Back To List" />
  </TopToolbar>
);

const RentCatalogShow = (props: any) => {
  return (
    <Show {...props} actions={<RentalCatalogShowActions />}>
      <TabbedShowLayout>
        <Tab label="Main">
          <TextField source="id" />
          <TextField source="displayName" />
          <RichTextField source="description" />
          <TextField source="onClickAction" />
          <ImageField source="imageUrl" />
          <TextField source="clickUrl" />
          <TextField source="fileUrl" />
          <TextField source="fileType" />
          <TextField source="position" />
          <BooleanField source="active" />

          <DateField source="createdAt" locales="id-ID" />
          <DateField source="updatedAt" locales="id-ID" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default RentCatalogShow;
