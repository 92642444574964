import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  EditProps,
} from "react-admin";

const CarouselEdit = (props: EditProps) => {
  return (
    <Edit {...props} redirect="list">
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput source="name" />
        <BooleanInput source="mainCarousel" />
      </SimpleForm>
    </Edit>
  );
};

export default CarouselEdit;
