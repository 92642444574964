import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  DateField,
  EditButton,
  ShowButton,
} from "react-admin";

const CategoryList = (props: any) => {
  return (
    <List {...props} sort={{ field: "id", order: "DESC" }}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="description" />
        <ImageField source="iconUrl" />
        <TextField source="status" />
        <DateField source="createdAt" locales="id-ID" />
        <DateField source="updatedAt" locales="id-ID" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default CategoryList;
