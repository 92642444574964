import CategoryList from "./CategoryList";
import CategoryCreate from "./CategoryCreate";
import CategoryEdit from "./CategoryEdit";
import CategoryIcon from "@mui/icons-material/Category";
import CategoryShow from "./CategoryShow";

const Categories = {
  create: CategoryCreate,
  list: CategoryList,
  edit: CategoryEdit,
  icon: CategoryIcon,
  show: CategoryShow,
  options: { label: "Categories" },
};

export default Categories;
