import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  ShowButton,
  EditButton,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";

const ProductFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Display Name" source="displayName" alwaysOn />
    <ReferenceInput
      label="Category"
      source="categoryId"
      reference="categories"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const ProductList = (props: any) => {
  return (
    <List
      {...props}
      sort={{ field: "id", order: "DESC" }}
      filters={<ProductFilter />}
    >
      <Datagrid>
        <TextField source="id" />
        <ReferenceField
          label="Category"
          source="subCategory.categoryId"
          reference="categories"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Sub Category"
          source="subCategoryId"
          reference="subcategories"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="slug" />
        <TextField source="name" />
        <TextField source="displayName" />
        <TextField source="pageTitle" />
        <TextField source="status" />
        <DateField source="updatedAt" locales="id-ID" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ProductList;
