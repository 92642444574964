import MediaList from "./MediaList";
import MediaCreate from "./MediaCreate";
import MediaIcon from "@mui/icons-material/PermMedia";

const Media = {
  list: MediaList,
  create: MediaCreate,
  options: { label: "Media" },
  icon: MediaIcon,
};

export default Media;
