import {
  List,
  Datagrid,
  TextField,
  DateField,
  EmailField,
  ShowButton,
} from "react-admin";

const UserList = (props: any) => {
  return (
    <List {...props} sort={{ field: "id", order: "DESC" }}>
      <Datagrid>
        <TextField source="id" />
        <EmailField source="email" />
        <TextField source="fullName" />
        <TextField source="phoneNumber" />
        <TextField source="status" />
        <TextField source="provider" />
        <DateField source="createdAt" locales="id-ID" />
        <DateField source="updatedAt" locales="id-ID" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default UserList;
