import {
  Edit,
  TextInput,
  SimpleForm,
  DateInput,
  NumberInput,
  SelectInput,
  ReferenceInput,
  useRecordContext,
} from "react-admin";

const Title = () => {
  const record = useRecordContext();
  return <span>Edit Currency Rates #{record ? `${record.id}` : ""}</span>;
};

const CurrencyRateEdit = (props: any) => {
  return (
    <Edit {...props} title={<Title />} redirect="list">
      <SimpleForm>
        <TextInput disabled source="id" />
        <ReferenceInput
          label="Source Currency"
          source="sourceCurrency"
          reference="currencies"
        >
          <SelectInput optionText="code" />
        </ReferenceInput>
        <ReferenceInput
          label="Target Currency"
          source="targetCurrency"
          reference="currencies"
        >
          <SelectInput optionText="code" />
        </ReferenceInput>
        <NumberInput source="exchangeRate" />
        <DateInput source="validFromDate" />
        <DateInput source="validToDate" />
      </SimpleForm>
    </Edit>
  );
};

export default CurrencyRateEdit;
