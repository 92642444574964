import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ShowButton,
  ListProps,
} from "react-admin";

const CarouselList = (props: ListProps) => {
  return (
    <List {...props} sort={{ field: "id", order: "DESC" }}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <BooleanField source="mainCarousel" />
        <TextField label="Gallery Total" source="carouselGalleries.length" />
        <DateField source="createdAt" locales="id-ID" />
        <DateField source="updatedAt" locales="id-ID" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default CarouselList;
