import OrderShippingCreate from "./OrderShippingCreate";
import OrderShippingEdit from "./OrderShippingEdit";

const OrderShippings = {
  create: OrderShippingCreate,
  edit: OrderShippingEdit,
  options: { label: "Order Shippings" },
};

export default OrderShippings;
