import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { withStyles } from "@mui/styles";
import { Button, useRecordContext } from "react-admin";

const styles = {
  button: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
};

const EditOrderShippingButton = ({ classes }: { classes: any }) => {
  const record = useRecordContext();
  return (
    <Button
      className={classes.button}
      variant="contained"
      component={Link as any}
      to={`/order-shippings/${record.id}?orderId=${record.orderId}`}
      label="Edit"
    >
      <EditIcon />
    </Button>
  );
};

export default withStyles(styles)(EditOrderShippingButton) as any;
