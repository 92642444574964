import React from "react";
import Typography from "@mui/material/Typography";
import { AppBar, Logout, useRedirect, UserMenu } from "react-admin";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SettingsIcon from "@mui/icons-material/Settings";

const ConfigurationMenu = React.forwardRef((props, ref) => {
  return (
    <MenuItem component={Link} {...props} to="/configuration">
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText>Configuration</ListItemText>
    </MenuItem>
  );
});

const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
    <Logout />
  </UserMenu>
);

const NewAppBar = (props: any) => {
  const redirect = useRedirect();

  return (
    <AppBar
      sx={{
        "& .RaAppBar-title": {
          flex: 1,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        },
        background: "#94807f",
        padding: "0.1rem",
      }}
      userMenu={<CustomUserMenu />}
      {...props}
    >
      <Typography
        variant="h6"
        color="inherit"
        id="react-admin-title"
        style={{
          flex: 1,
        }}
      />
      <img
        src="https://titianjewelry.s3-ap-southeast-1.amazonaws.com/images/logo-new-titian-white-ls.png"
        alt="Titian Jewelry"
        width={100}
        height={40}
        style={{
          cursor: "pointer",
        }}
        onClick={() => redirect("/")}
      ></img>
      <span
        style={{
          flex: 1,
        }}
      />
    </AppBar>
  );
};

export default NewAppBar;
