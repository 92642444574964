export const variantPropertySelection = () => {
  return [
    { id: "color", name: "color" },
    { id: "type", name: "type" },
    { id: "size", name: "size" },
  ];
};

export const RICH_TEXT_INPUT_TOOLBARS = [
  ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ["clean"],
];
